import request from '@/utils/request'

export function fetchList(params) {
    return request({
        url: '/v1/blog',
        method: 'get',
        params: {
            page: 1,
            page_size: 10,
        }
    })
}

export function fetchFocus(params) {
    return request({
        url: '/v1/blog',
        method: 'get',
        params: {
            page: params.page,
            page_size: params.page_size,
        }
    })
}

export function fetchCategory() {
    return request({
        url: '/category',
        method: 'get',
        params: {}
    })
}

export function fetchFriend() {
    return request({
        url: '/friend',
        method: 'get',
        params: {}
    })
}

export function fetchSocial() {
    return request({
        url: '/v1/social',
        method: 'get',
        params: {}
    });
}

export function fetchSiteInfo() {
    return request({
        url: '/v1/site',
        method: 'get',
        params: {}
    })
}

export function fetchComment() {
    return request({
        url: '/comment',
        method: 'get',
        params: {}
    })
}

export function fetchBlogInfo(id) {
    return request({
        url: '/v1/blog/info',
        method: 'get',
        params: {
            id: id,
        }
    })
}
