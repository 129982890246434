import axios from 'axios'
import {Decrypt, Encrypt, randomString} from "@/utils/index";

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        // if (store.getters.token) {
        //     config.headers['X-Token'] = getToken()
        // }
        config.headers["timestamp"] = Date.parse(new Date());
        config.headers["rands-str"] = randomString(12);
        if (JSON.stringify(config.params) !== "{}") {
            const p = config.params;
            config.params = {}
            if (config.method === 'get') {
                config.params['param'] = Encrypt(p)
            } else {
                config.params = {'param': Encrypt(p)}
            }

        }

        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = Decrypt(response.data)
        // store.commit('SET_LOADING',false);

        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 200) {
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

export default service
